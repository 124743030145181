import axios from 'axios'
import moment from 'moment'

import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'
import { getToken } from 'src/helpers/token'

const master = 'financing'
const type = 'pendaftaran-haji'

export const actionType = (action) => {
  return {
    request: `${action}_${type}_request`.toUpperCase(),
    success: `${action}_${type}_success`.toUpperCase(),
    error: `${action}_${type}_error`.toUpperCase(),
  }
}

const request = httpService()

export default {
  ...baseActions(master, URL_API, actionType),
  exportFile: (params) => {
    return (dispatch) => {
      dispatch({ type: actionType('exportFile').request })
      return request.get(`${URL_API}/financing/export`, { params, responseType: 'blob' }).then(
        (response) => {
          dispatch({ type: actionType('exportFile').success })
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `pendaftaran-porsi-haji${moment().format('DDMMYYYY')}.xlsx`)
          document.body.appendChild(link)
          link.click()
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('exportFile').error })
          throw error
        },
      )
    }
  },
  exportFileByID: (id, name) => {
    return (dispatch) => {
      dispatch({ type: actionType('exportFile').request })
      return axios({
        method: 'POST',
        headers: {
          Authorization: getToken(),
        },
        url: `${URL_API}/financing/export/${id}/porsi-haji`,
        responseType: 'blob',
      }).then(
        (response) => {
          dispatch({ type: actionType('exportFile').success })
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${name}.pdf`)
          document.body.appendChild(link)
          link.click()
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('exportFile').error })
          throw error
        },
      )
    }
  },
}
