import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const request = httpService()
const master = 'umrah-registration'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  ...baseActions(master, URL_API, actionType),
  updateStatusUmroh: (type, id, data) => {
    return (dispatch) => {
      dispatch({ type: actionType('update').request })
      return request.put(`${URL_API}/${master}/${type}/id/${id}`, data).then(
        (response) => {
          dispatch({ type: actionType('update').success })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('update').error })
          throw error.response
        },
      )
    }
  },
  exportFile: (params, nameFile = 'transaction') => {
    return (dispatch) => {
      dispatch({ type: actionType('exportFile').request })
      return request
        .get(`${URL_API}/umrah-registration/export`, {
          params,
          responseType: 'blob',
        })
        .then(
          (response) => {
            dispatch({ type: actionType('exportFile').success })
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${nameFile}.xlsx`)
            document.body.appendChild(link)
            link.click()
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('exportFile').error })
            throw error
          },
        )
    }
  },
  statusDocument: (id) => {
    return (dispatch) => {
      dispatch({ type: actionType('statusDocument').request })
      return request
        .get(`${URL_API}/umrah-registration-detail/${id}`, {
          params: {
            order: 'def.created_at',
            sort: 'desc',
          },
        })
        .then(
          (response) => {
            dispatch({ type: actionType('statusDocument').success })
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('statusDocument').error })
            throw error.response
          },
        )
    }
  },
}
