import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'

const master = 'tabungan-umroh/config'
const request = httpService()

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  getConfig: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('tabungan-umroh/config').request })
      return request.get(`${URL_API}/tabungan-umroh/config`, data).then(
        (response) => {
          dispatch({ type: actionType('tabungan-umroh/config').success, payload: response.data })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('tabungan-umroh/config').error })
          throw error.response
        },
      )
    }
  },
  updateConfig: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('tabungan-umroh/config').request })
      return request.put(`${URL_API}/tabungan-umroh/config`, data).then(
        (response) => {
          dispatch({ type: actionType('tabungan-umroh/config').success, payload: data })
          return response.data.data
        },
        (error) => {
          dispatch({ type: actionType('tabungan-umroh/config').error })
          throw error.response
        },
      )
    }
  },
}
