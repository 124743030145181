import React, { Suspense } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import 'src/assets/styles/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="animate-pulse bg-gray-300 h-8 w-8 rounded-full mx-auto" />
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('src/container/TheLayout'))

// Pages
const Login = React.lazy(() => import('src/views/login/Login'))
const ForgotPassword = React.lazy(() => import('src/views/forgotPassword/ForgotPassword'))
const VerifikasiEmail = React.lazy(() => import('src/views/verifikasiEmail/VerifikasiEmail'))
const NewPassword = React.lazy(() => import('src/views/newPassword/NewPassword'))

const App = () => (
  <BrowserRouter>
    <Suspense fallback={loading}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verifikasi-email" element={<VerifikasiEmail />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/*" element={<TheLayout />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
)

export default App
