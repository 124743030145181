import baseInitState from 'src/helpers/redux/baseInitState'
import baseReducer from 'src/helpers/redux/baseReducer'

import { actionType } from './rekonsiliasi.action'

const initState = {
  ...baseInitState,
  isLoadingRekonsil: false,
  isLoadingExport: false,
  transCategoryTab: 'marketplace',
  rekonCategoryTab: 'marketplace',
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actionType('bulkRekonsil').request:
      return {
        ...state,
        isLoadingRekonsil: true,
      }
    case actionType('bulkRekonsil').success:
      return {
        ...state,
        isLoadingRekonsil: false,
      }
    case actionType('bulkRekonsil').error:
      return {
        ...state,
        isLoadingRekonsil: false,
      }
    case 'TRANS_CATEGORY_TAB':
      return {
        ...state,
        transCategoryTab: action?.value,
      }
    case 'REKON_CATEGORY_TAB':
      return {
        ...state,
        rekonCategoryTab: action?.value,
      }
    case actionType('exportFile').request:
      return {
        ...state,
        isLoadingExport: true,
      }
    case actionType('exportFile').success:
      return {
        ...state,
        isLoadingExport: false,
      }
    case actionType('exportFile').error:
      return {
        ...state,
        isLoadingExport: false,
      }
    default:
      return baseReducer(state, action, actionType)
  }
}
