import { URL_API } from 'src/configs/keys'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'theme'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

const setTheme = (theme) => {
  return (dispatch) => {
    dispatch({ type: actionType('setTheme').request })
    try {
      localStorage.setItem('theme', theme)
      dispatch({
        type: actionType('setTheme').success,
        payload: theme,
      })
    } catch (error) {
      dispatch({
        type: actionType('setTheme').error,
        payload: error.message,
      })
    }
  }
}

export default {
  ...baseActions(master, URL_API, actionType),
  setTheme,
}
