import baseInitState from 'src/helpers/redux/baseInitState'
import baseReducer from 'src/helpers/redux/baseReducer'

import { actionType } from './listPendaftaranAgent.action'

const initState = {
  ...baseInitState,
  isLoadingExport: false,
  config: null,
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actionType('exportFile').request:
      return {
        ...state,
        isLoadingExport: true,
      }
    case actionType('exportFile').success:
    case actionType('exportFile').error:
      return {
        ...state,
        isLoadingExport: false,
      }
    case actionType('agent_submission').request:
      return {
        ...state,
        isLoading: true,
      }
    case actionType('agent_submission').success:
      return {
        ...state,
        isLoading: false,
        config: action.payload.data,
      }
    case actionType('agent_submission').error:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return baseReducer(state, action, actionType)
  }
}
