import baseInitState from './baseInitState'

const baseReducer = (state, action, actionType) => {
  switch (action.type) {
    case actionType('fetchList').request:
      return {
        ...state,
        isLoading: true,
      }
    case actionType('fetchList').success:
      return {
        ...state,
        isLoading: false,
        dataList: action.payload.data ? action.payload.data : [],
        pagination: {
          currentPage: action.payload?.meta?.current_page || 1,
          lastPage: action.payload?.meta?.last_page || 1,
          count: action.payload?.meta?.total || 0,
          recordPerPage: action.payload?.meta?.per_page || 10,
        },
        params: action.params,
      }
    case actionType('fetchList').error:
      return {
        ...state,
        isLoading: false,
      }

    case actionType('fetchAll').request:
      return {
        ...state,
        isLoading: true,
      }
    case actionType('fetchAll').success:
      return {
        ...state,
        isLoading: false,
        dataAll: action.payload.data ? action.payload.data : [],
      }
    case actionType('fetchAll').error:
      return {
        ...state,
        isLoading: false,
      }

    case actionType('fetchDetail').request:
      return {
        ...state,
        isLoadingDetail: true,
      }
    case actionType('fetchDetail').success:
      return {
        ...state,
        isLoadingDetail: false,
        dataDetail: action.payload.data,
      }
    case actionType('fetchDetail').error:
      return {
        ...state,
        isLoadingDetail: false,
        dataDetail: null,
      }

    case actionType('create').request:
      return {
        ...state,
        isLoadingSubmit: true,
      }
    case actionType('create').success:
      return {
        ...state,
        isLoadingSubmit: false,
      }
    case actionType('create').error:
      return {
        ...state,
        isLoadingSubmit: false,
      }

    case actionType('update').request:
      return {
        ...state,
        isLoadingSubmit: true,
      }
    case actionType('update').success:
      return {
        ...state,
        isLoadingSubmit: false,
      }
    case actionType('update').error:
      return {
        ...state,
        isLoadingSubmit: false,
      }

    case actionType('delete').request:
      return {
        ...state,
        isLoadingDelete: true,
      }
    case actionType('delete').success:
      return {
        ...state,
        isLoadingDelete: false,
      }
    case actionType('delete').error:
      return {
        ...state,
        isLoadingDelete: false,
      }
    case actionType('resetParams').success:
      return {
        ...baseInitState,
        params: {
          ...baseInitState.params,
          status: '',
          type: '',
        },
      }
    case actionType('setDataForm').success:
      return {
        ...state,
        ...action.dataForm,
      }
    default:
      return state
  }
}
export default baseReducer
