import { actionType } from './masterData.action'

const initState = {
  cities: [],
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actionType('getCity').success:
      return {
        ...state,
        cities: action.payload.data,
      }

    default:
      return state
  }
}
