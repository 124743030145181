import { URL_API } from 'src/configs/keys'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'promo/partner'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  ...baseActions(master, URL_API, actionType),
  refetchList: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('fetchList').success, payload: data })
      return data
    }
  },
}
