import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'infaq'
const request = httpService()

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  ...baseActions(master, URL_API, actionType),
  exportFile: () => {
    return (dispatch) => {
      dispatch({ type: actionType('exportFile').request })
      return request
        .get(`${URL_API}/infaq/export`, {
          responseType: 'blob',
        })
        .then(
          (response) => {
            dispatch({ type: actionType('exportFile').success })
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'infaq.xlsx')
            document.body.appendChild(link)
            link.click()
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('exportFile').error })
            throw error.response
          },
        )
    }
  },
}
