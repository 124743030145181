import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'

const master = 'santrimedia-config'
const request = httpService()

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  getConfig: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('santrimedia-config').request })
      return request.get(`${URL_API}/santrimedia-config`, data).then(
        (response) => {
          dispatch({ type: actionType('santrimedia-config').success, payload: response.data })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('santrimedia-config').error })
          throw error.response
        },
      )
    }
  },
  updateConfig: (id, data) => {
    return (dispatch) => {
      dispatch({ type: actionType('santrimedia-config/id').request })
      return request.put(`${URL_API}/santrimedia-config/id/${id}`, data).then(
        (response) => {
          dispatch({ type: actionType('santrimedia-config/id').success })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('santrimedia-config/id').error })
          throw error.response
        },
      )
    }
  },
}
