import axios from 'axios'

import { BASIC_PASSWORD, BASIC_USERNAME, URL_API_MASTER_DATA } from 'src/configs/keys'
import { alertError } from 'src/helpers/swalAlert'
import { translateMessage } from 'src/helpers/translateMessage'

export const actionType = (action) => {
  return {
    request: `${action}_request`.toUpperCase(),
    success: `${action}_success`.toUpperCase(),
    error: `${action}_error`.toUpperCase(),
  }
}

export default {
  getCity: () => {
    return (dispatch) => {
      axios({
        method: 'GET',
        url: `${URL_API_MASTER_DATA}/city`,
        auth: {
          username: BASIC_USERNAME,
          password: BASIC_PASSWORD,
        },
      })
        .then((response) => {
          dispatch({ type: actionType('getCity').success, payload: response.data })
          return response.data
        })
        .catch((error) => {
          if (error.response) {
            const { messages, status_code } = error.response.data
            if (status_code === 422) {
              alertError(`Error!, ${translateMessage(messages)}`)
            }
          } else {
            alertError(`Error!, ${translateMessage(error.message)}`)
          }
        })
    }
  },
}
