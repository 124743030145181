import { actionType } from './configSantrimedia.action'

const initState = {
  isLoading: false,
  config: {},
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actionType('santrimedia-config').request:
      return {
        ...state,
        isLoading: true,
      }
    case actionType('santrimedia-config').success:
      return {
        ...state,
        isLoading: false,
        config: action.payload.data,
      }
    case actionType('santrimedia-config').error:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
