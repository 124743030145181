import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'partner'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

const request = httpService()
export default {
  ...baseActions(master, URL_API, actionType),
  updateZakatFitrah: (data) => {
    return () => {
      return request
        .put(`${URL_API}/type-zakat/fitrah`, data)
        .then((res) => {
          return res
        })
        .catch((error) => {
          throw error.response
        })
    }
  },
  getZakatFitrah: () => {
    return (dispatch) => {
      return request
        .get(`${URL_API}/type-zakat/fitrah`)
        .then((res) => {
          dispatch({ type: actionType('getZakatFitrah').success, payload: res.data })
          return res.data
        })
        .catch((error) => {
          throw error.response
        })
    }
  },
  exportFile: (params, name = 'partner') => {
    return (dispatch) => {
      dispatch({ type: actionType('exportFile').request })
      return request.get(`${URL_API}/partner/exportExcel`, { params, responseType: 'blob' }).then(
        (response) => {
          dispatch({ type: actionType('exportFile').success })
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${name}.xlsx`)
          document.body.appendChild(link)
          link.click()
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('exportFile').error })
          throw error.response
        },
      )
    }
  },
  fetchAll: (params) => {
    return (dispatch) => {
      dispatch({ type: actionType('fetchAll').request })
      return request.get(`${URL_API}/${master}/selectAll`, { params }).then(
        (response) => {
          dispatch({
            type: actionType('fetchAll').success,
            payload: response.data,
          })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('fetchAll').error })
          throw error.response
        },
      )
    }
  },
}
