import Swal from 'sweetalert2'

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
})

export const alertSuccess = (text) => {
  return swalWithBootstrapButtons.fire({
    title: 'Sukses!',
    text: text || 'Operasi berhasil.',
    icon: 'success',
    confirmButtonText: 'Oke',
  })
}
export const alertError = (text) => {
  return Swal.fire({
    html: `
    <div class="d-flex align-items-center mt-2">
      <img src="/images/icons/error.svg" width="38" class="mr-3" />
      ${text || 'Terjadi kesalahan'}
    </div>
    `,
    confirmButtonColor: '#d33',
    confirmButtonText: 'Tutup',
  })
}
export const alertWarning = (text) => {
  return Swal.fire({
    html: `
    <div class="d-flex align-items-center mt-2">
      <img src="/images/icons/warning.svg" width="38" class="mr-3" />
      ${text || 'Warning'}
    </div>
    `,
    confirmButtonColor: '#321fdb',
    confirmButtonText: 'Oke',
  })
}
