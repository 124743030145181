import baseInitState from 'src/helpers/redux/baseInitState'
import baseReducer from 'src/helpers/redux/baseReducer'

import { actionType } from './pendaftaranUmroh.action'

const initState = {
  ...baseInitState,
  params: {
    ...baseInitState.params,
    order: 'def.id',
  },
  isLoadingExport: false,
  listStatusDocument: [],
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actionType('exportFile').request:
      return {
        ...state,
        isLoadingExport: true,
      }
    case actionType('exportFile').success:
    case actionType('exportFile').error:
      return {
        ...state,
        isLoadingExport: false,
      }

    default:
      return baseReducer(state, action, actionType)
  }
}
