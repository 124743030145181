import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'promo/master-aqiqah'
const request = httpService()

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  ...baseActions(master, URL_API, actionType),
  fetchList: (params) => {
    return (dispatch) => {
      dispatch({ type: actionType('fetchList').request })
      return request.get(`${URL_API}/${master}`, { params }).then(
        (response) => {
          // dispatch({ type: actionType('fetchList').success, payload: response.data, params })
          return response.data
        },
        (error) => {
          // dispatch({ type: actionType('fetchList').error })
          throw error.response
        },
      )
    }
  },
}
