import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'umrah'
const request = httpService()
export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  ...baseActions(master, URL_API, actionType),
  fetchPromo: (id) => {
    return () => {
      return request.get(`${URL_API}/umrah-promo/umrah_id/${id}`).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  addPromo: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('promoUmroh').request })
      return request.post(`${URL_API}/umrah-promo`, data).then(
        (response) => {
          dispatch({ type: actionType('promoUmroh').success })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('promoUmroh').error })
          throw error.response
        },
      )
    }
  },
  updatePromo: (id, data) => {
    return (dispatch) => {
      dispatch({ type: actionType('promoUmroh').request })
      return request
        .put(`${URL_API}/umrah-promo/id/${id}`, data)
        .then((response) => {
          dispatch({ type: actionType('promoUmroh').success })
          return response.data
        })
        .catch((e) => {
          dispatch({ type: actionType('promoUmroh').error })
          return e.response.data
        })
    }
  },
}
