const initialState = {
  sidebarShow: localStorage.getItem('sidebarShow') || 'responsive',
}

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SIDEBAR':
      return {
        ...state,
        sidebarShow: action.sidebarShow,
      }
    default:
      return state
  }
}

export default sidebarReducer
