import { actionType } from './configIntroTabungan.action'

const initState = {
  isLoading: false,
  config: {},
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actionType('tabungan-umroh/config').request:
      return {
        ...state,
        isLoading: true,
      }
    case actionType('tabungan-umroh/config').success:
      return {
        ...state,
        isLoading: false,
        config: action.payload.data,
      }
    case actionType('tabungan-umroh/config').error:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
