import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import { setToken } from 'src/helpers/token'

const master = 'auth'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

const request = httpService()
export default {
  login: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('login').request })
      return request.post(`${URL_API}/admin/login`, data).then(
        (response) => {
          dispatch({ type: actionType('login').success })
          setToken(response.data.data.token)
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('login').error })
          throw error.response
        },
      )
    }
  },
  getToken: () => {
    return (dispatch) => {
      return request.get(`${URL_API}/admin/token`).then(
        (response) => {
          dispatch({ type: actionType('profile').success, payload: response.data })
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  updateProfile: (data) => {
    return () => {
      return request.put(`${URL_API}/admin/token`, data).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  updatePassword: (data) => {
    return () => {
      return request.put(`${URL_API}/admin/edit-password`, data).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  forgotPassword: (data) => {
    return () => {
      return request.post(`${URL_API}/forgot-password`, data).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  resendOTP: () => {
    return () => {
      return request.post(`${URL_API}/otp/resend`).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  otpVerify: (data) => {
    return () => {
      return request.post(`${URL_API}/otp/verify`, data).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
  resetPassword: (data) => {
    return () => {
      return request.put(`${URL_API}/reset`, data).then(
        (response) => {
          return response.data
        },
        (error) => {
          throw error.response
        },
      )
    }
  },
}
