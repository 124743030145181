import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const config = 'kurban/config'
const request = httpService()

export const actionType = (action) => {
  return {
    request: `${action}_${config}_request`.toUpperCase(),
    success: `${action}_${config}_success`.toUpperCase(),
    error: `${action}_${config}_error`.toUpperCase(),
  }
}

export default {
  ...baseActions(config, URL_API, actionType),
  fetchList: (params) => {
    return (dispatch) => {
      dispatch({ type: actionType('fetchList').request })
      return request.get(`${URL_API}/${config}`, { params }).then(
        (response) => {
          dispatch({ type: actionType('fetchList').success, payload: response.data })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('fetchList').error })
          throw error.response
        },
      )
    }
  },
  createAndupdate: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('update').request })
      return request.put(`${URL_API}/${config}`, data).then(
        (response) => {
          dispatch({ type: actionType('update').success, payload: data })
          return response.data.data
        },
        (error) => {
          dispatch({ type: actionType('update').error })
          throw error.response
        },
      )
    }
  },
}
