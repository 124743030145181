import { URL_API } from 'src/configs/keys'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'fee-payment'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  ...baseActions(master, URL_API, actionType),
  updatePartnerNominal: (id, nominal) => (dispatch, getState) => {
    const currentState = getState().feePayment
    const updatedList = currentState.dataList?.map((item) =>
      item.id === id ? { ...item, nominal } : item,
    )

    dispatch({
      type: actionType('updatePartnerNominal').success,
      payload: updatedList,
    })
  },
  updateStatus: (id, status) => (dispatch, getState) => {
    const currentState = getState().feePayment
    const updatedList = currentState.dataList?.map((item) =>
      item.id === id ? { ...item, status } : item,
    )

    dispatch({
      type: actionType('updateStatus').success,
      payload: updatedList,
    })
  },
}
