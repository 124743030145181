import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'kurban/jenis-hewan'
const request = httpService()

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  ...baseActions(master, URL_API, actionType),
  fetchDetail: (id, params, baseUrl) => {
    return (dispatch) => {
      dispatch({ type: actionType('fetchDetail').request })
      return request.get(`${baseUrl}/${master}/id/${id}`, { params }).then(
        (response) => {
          dispatch({ type: actionType('fetchDetail').success, payload: response.data })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('fetchDetail').error })
          throw error.response
        },
      )
    }
  },
  createAndupdate: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('update').request })
      return request.put(`${URL_API}/${master}`, data).then(
        (response) => {
          dispatch({ type: actionType('update').success, payload: data })
          return response.data.data
        },
        (error) => {
          dispatch({ type: actionType('update').error })
          throw error.response
        },
      )
    }
  },
}
