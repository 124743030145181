import baseInitState from 'src/helpers/redux/baseInitState'
import baseReducer from 'src/helpers/redux/baseReducer'

import { actionType } from './theme.action'

const initState = {
  ...baseInitState,
  isDarkMode:
    localStorage.getItem('theme') === 'dark' ||
    (!localStorage.getItem('theme') && getSystemTheme() === 'dark') ||
    (localStorage.getItem('theme') === 'system' && getSystemTheme() === 'dark'),
  theme: localStorage.getItem('theme') || 'system',
}

function getSystemTheme() {
  return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actionType('setTheme').request:
      return {
        ...state,
        loading: true,
      }
    case actionType('setTheme').success:
      const currentTheme = action.payload === 'system' ? getSystemTheme() : action.payload
      return {
        ...state,
        loading: false,
        isDarkMode: currentTheme === 'dark',
        theme: action.payload,
      }
    case actionType('setTheme').error:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return baseReducer(state, action, actionType)
  }
}
