import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const request = httpService()
const master = 'agent'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  ...baseActions(master, URL_API, actionType),
  getConfig: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('agent_submission').request })
      return request.get(`${URL_API}/config/code/agent_submission`, data).then(
        (response) => {
          dispatch({ type: actionType('agent_submission').success, payload: response.data })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('agent_submission').error })
          throw error.response
        },
      )
    }
  },
  updateConfig: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('agent_submission_EDIT').request })
      return request.post(`${URL_API}/config/edit`, data).then(
        (response) => {
          dispatch({ type: actionType('agent_submission_EDIT').success })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('agent_submission_EDIT').error })
          throw error.response
        },
      )
    }
  },
  exportFile: (params, nameFile) => {
    return (dispatch) => {
      dispatch({ type: actionType('exportFile').request })
      return request
        .get(`${URL_API}/agent/export`, {
          params,
          responseType: 'blob',
        })
        .then(
          (response) => {
            dispatch({ type: actionType('exportFile').success })
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${nameFile}.xlsx`)
            document.body.appendChild(link)
            link.click()
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('exportFile').error })
            throw error.response
          },
        )
    }
  },
}
