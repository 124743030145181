import { URL_API, URL_API_V2 } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'
import baseActions from 'src/helpers/redux/baseActions'

const master = 'transaction'

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

const request = httpService()
export default {
  ...baseActions(master, URL_API_V2, actionType),
  bulkRekonsil: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('bulkRekonsil').request })
      return request.post(`${URL_API}/${master}/bulk-recon`, data).then(
        (response) => {
          dispatch({ type: actionType('bulkRekonsil').success })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('bulkRekonsil').error })
          throw error.response
        },
      )
    }
  },
  exportFile: (params) => {
    return (dispatch) => {
      dispatch({ type: actionType('exportFile').request })
      return request
        .get(`${URL_API_V2}/transaction/exportExcel`, { params, responseType: 'blob' })
        .then(
          (response) => {
            dispatch({ type: actionType('exportFile').success })
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'transaction.xlsx')
            document.body.appendChild(link)
            link.click()
            return response.data
          },
          (error) => {
            dispatch({ type: actionType('exportFile').error })
            throw error.response
          },
        )
    }
  },
}
