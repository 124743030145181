// Impor fungsi untuk membuat kelas CSS dinamis
// import { twMerge } from 'tailwind-merge'
// Tambahkan logo khusus
import React from 'react'

import { logo } from './logo'
import { logoNegative } from './logo-negative'
import { sygnet } from './sygnet'

// Fungsi untuk membuat gaya ikon
const iconStyles = ({ size = 'md', color = 'primary', className = '' }) => {
  const baseStyles = 'inline-block'
  const sizeStyles = {
    sm: 'w-4 h-4',
    md: 'w-6 h-6',
    lg: 'w-8 h-8',
  }
  const colorStyles = {
    primary: 'text-blue-600',
    secondary: 'text-gray-600',
    success: 'text-green-600',
    danger: 'text-red-600',
    warning: 'text-yellow-600',
    info: 'text-cyan-600',
  }

  return `${baseStyles} ${sizeStyles[size]} ${colorStyles[color]} ${className}`.trim()
}

// Buat objek icons dengan menggunakan Tailwind CSS
export const icons = {
  logo,
  logoNegative,
  sygnet,
}

// Fungsi untuk membuat komponen ikon
export const createIcon = (path) => {
  return ({ size, color, className, ...props }) => (
    <svg className={iconStyles({ size, color, className })} viewBox="0 0 512 512" {...props}>
      <path fill="currentColor" d={path} />
    </svg>
  )
}
