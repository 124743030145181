import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'

const master = 'course-layout'
const request = httpService()

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  getConfig: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('course-layout').request })
      return request.get(`${URL_API}/course-layout`, data).then(
        (response) => {
          dispatch({ type: actionType('course-layout').success, payload: response.data })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('course-layout').error })
          throw error.response
        },
      )
    }
  },
  updateConfig: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('course-layout').request })
      return request.post(`${URL_API}/course-layout`, data).then(
        (response) => {
          dispatch({ type: actionType('course-layout').success, payload: data })
          return response.data.data
        },
        (error) => {
          dispatch({ type: actionType('course-layout').error })
          throw error.response
        },
      )
    }
  },
}
