import { URL_API } from 'src/configs/keys'
import httpService from 'src/helpers/httpService'

const master = 'file'
const request = httpService()

export const actionType = (action) => {
  return {
    request: `${action}_${master}_request`.toUpperCase(),
    success: `${action}_${master}_success`.toUpperCase(),
    error: `${action}_${master}_error`.toUpperCase(),
  }
}

export default {
  uploadFile: (data) => {
    return (dispatch) => {
      dispatch({ type: actionType('upload').request })
      return request.post(`${URL_API}/${master}`, data).then(
        (response) => {
          dispatch({ type: actionType('upload').success })
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('upload').error })
          throw error.response
        },
      )
    }
  },
  downloadURL: (url, name) => {
    return (dispatch) => {
      dispatch({ type: actionType('download_url').request })
      return request.get(url, { responseType: 'blob' }).then(
        (response) => {
          dispatch({ type: actionType('download_url').success })
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
          return response.data
        },
        (error) => {
          dispatch({ type: actionType('download_url').error })
          throw error.response
        },
      )
    }
  },
}
