import baseInitState from 'src/helpers/redux/baseInitState'
import baseReducer from 'src/helpers/redux/baseReducer'

import { actionType } from './aqiqahMaster.action'

const initState = {
  ...baseInitState,
  isLoadingExport: false,
}

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actionType('action').request:
      return {
        ...state,
        isLoadingSubmit: true,
      }
    case actionType('action').success:
      return {
        ...state,
        isLoadingSubmit: false,
      }
    case actionType('action').error:
      return {
        ...state,
        isLoadingSubmit: false,
      }
    default:
      return baseReducer(state, action, actionType)
  }
}
